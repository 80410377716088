import React, { useEffect, useState } from 'react';
import './App.css'; // Добавь свои стили сюда

const App = () => {
  const [showLogo, setShowLogo] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowLogo(true);
      setTimeout(() => {
        setShowButton(true);
      }, 800);
    }, 2000);
  }, []);

  return (
      <div style={{ color: '#fff' }}>
        <div className="cssload-thecube">
          <div className="cssload-cube cssload-c1"></div>
          <div className="cssload-cube cssload-c2"></div>
          <div className="cssload-cube cssload-c4"></div>
          <div className="cssload-cube cssload-c3"></div>
        </div>
        <div className="overlay"></div>

        {showLogo && (
            <div className="logo">
              <img src="/images/logo.png?v=13" alt="Logo" />
            </div>
        )}
        {showButton && (
            <a href="/netcat/modules/game/start.php">
              <div className="button-start">Войти в игру</div>
            </a>
        )}
      </div>
  );
};

export default App;
